<template>
  <el-main>
    <el-form class="el-form-search" label-width="115px">
      <el-form-item label="评论关键字：">
        <el-input size="small" placeholder="请输入评价内容关键字" v-model="content"></el-input>
      </el-form-item>
      <el-form-item label="评价日期：">
        <el-date-picker v-model="timeVal" size="small" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" @change="getTime"></el-date-picker>
      </el-form-item>
      <el-form-item label="订单编号：">
        <el-input size="small" placeholder="请输入订单编号" v-model="pay_sn"></el-input>
      </el-form-item>
      <el-form-item label="评分等级：">
        <el-select v-model="score" size="small" placeholder="请选择">
          <el-option v-for="item in levelSelect" :key="item.val" :label="item.label" :value="item.val"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="getList()">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <div class="order-list">
      <table class="order-table">
        <thead>
          <tr>
            <th>服务信息</th>
            <th>用户评价</th>
            <th></th>
          </tr>
          <tr style="height: 10px"></tr>
        </thead>
        <tbody v-if="!list.length">
          <tr style="background: #fff">
            <td colspan="3">
              <No />
            </td>
          </tr>
        </tbody>
        <tbody v-for="(item, index) in list" :key="index">
          <tr>
            <td colspan="3">订单编号：{{ item.pay_sn }}</td>
          </tr>
          <tr>
            <td>
              <div class="service-info">
                <el-image :src="item.service_picture"></el-image>
                <div class="service-title">
                  <p>{{ item.service_title }}</p>
                  <p>
                    <el-rate v-model="item.score" disabled></el-rate>
                    <span>{{ item.score }}</span>
                  </p>
                </div>
              </div>
            </td>
            <td>
              <div class="evaluate-info">
                <div>
                  <span>评价用户：</span>
                  {{ item.nickname }}
                </div>
                <div style="display: flex">
                  <span style="flex-shrink: 0">评价内容：</span>
                  <p style="line-height: 16px">{{ item.content }}</p>
                </div>
                <div class="image" v-if="item.picture">
                  <span>评价图片：</span>
                  <el-image :src="pictures" v-for="(pictures, indexs) in item.picture" :preview-src-list="item.picture" :key="indexs"></el-image>
                </div>
                <div>
                  <span>评价时间：</span>
                  {{ getDateformat(item.create_time) }}
                </div>
              </div>
            </td>
            <td>
              <div class="complete-info">
                <div>
                  <span>接单师傅：</span>
                  {{ item.name }}
                </div>
                <div class="complete-img" v-if="item.complete_picture">
                  <span>验收图片：</span>
                  <el-image :src="pictures" :preview-src-list="item.complete_picture" v-for="(pictures, indexs) in item.complete_picture" :key="indexs"></el-image>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>
<script>
import { getDateformat } from '../../util/getDate';
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      rows: 10,
      page: 1,
      total_number: 0,
      content: '',
      start_time: '',
      end_time: '',
      pay_sn: '',
      score: 0,
      list: [],
      timeVal: '',
      levelSelect: [
        {
          val: 0,
          label: '全部',
        },
        {
          val: 1,
          label: '一级',
        },
        {
          val: 2,
          label: '二级',
        },
        {
          val: 3,
          label: '三级',
        },
        {
          val: 4,
          label: '四级',
        },
        {
          val: 5,
          label: '五级',
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
        this.getList();
      } else {
        this.page = val;
        this.getList();
      }
    },
    cancelSearch() {
      this.content = '';
      this.pay_sn = '';
      this.score = 0;
      this.timeVal = '';
      this.start_time = '';
      this.end_time = '';
      this.getList();
    },
    getTime(val) {
      if (val) {
        this.start_time = val[0].getTime() / 1000;
        this.end_time = val[1].getTime() / 1000;
      } else {
        this.start_time = '';
        this.end_time = '';
      }
    },
    getList() {
      let data = {
        content: this.content,
        pay_sn: this.pay_sn,
        score: this.score,
        page: this.page,
        rows: this.rows,
        start_time: this.start_time,
        end_time: this.end_time,
      };
      this.$axios.post(this.$api.samecity.service.comment, data).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          this.total_number = res.result.total_number;
          this.list = list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .order-table {
    width: 100%;
    font-size: 14px;
    tbody {
      border: 1px solid #ebeef5;
    }
    thead tr th {
      padding: 15px;
    }
    thead tr {
      background: #f8f9fa;
      th:first-child {
        width: 350px;
      }
      td:last-child {
        width: 350px;
      }
    }
    tbody tr:first-child {
      border-bottom: 1px solid #ebeef5;
      background: #f8f9fa;
      td {
        padding: 15px 20px;
      }
    }
    tbody tr:last-child {
      td {
        padding: 15px 20px;
      }
      td:first-child {
        width: 350px;
      }
      td:last-child {
        width: 350px;
      }
    }
  }
  .service-info {
    display: flex;
    align-items: center;
    .el-image {
      width: 70px;
      height: 70px;
      border-radius: 5px;
      flex-shrink: 0;
    }
    .service-title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
      text-align: left;
      height: 70px;
      p:nth-child(1) {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      p:nth-child(2) {
        display: flex;
        align-items: center;
      }
    }
  }
  .evaluate-info,
  .complete-info {
    span {
      font-weight: bold;
    }
    & > div {
      margin-bottom: 10px;
    }
    .image,
    .complete-img {
      display: flex;
      flex-wrap: wrap;
      .el-image {
        width: 70px;
        height: 70px;
        border-radius: 5px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
